import React from 'react';


import styles from './AmountSelector.module.scss';
import MoneyAmount from "../../../../components/UI/MoneyAmount";

interface Props {
  amount: number;
  currency: string;
  onChange: (amount: number) => void
}

const MIN_AMOUNT = 5;

const AmountSelector = ({amount, currency, onChange}: Props) => {
  const decAmount = () => {
    let newValue = amount - (amount <= 30 ? 5 : 10);
    onChange(newValue < MIN_AMOUNT ? MIN_AMOUNT : newValue);
  }

  const incAmount = () => {
    let newValue = amount + (amount <= 30 ? 5 : 10);
    onChange(newValue == 35 ? 40 : newValue);
  }
  return (
    <div className={`${styles.AmountSelector}`}>
      <div className={styles.selector}>
        <div className={styles.decBtn} onClick={decAmount}>-</div>
        <div className={styles.value}>
          <MoneyAmount amount={amount} currency={currency}/>
        </div>
        <div className={styles.incBtn} onClick={incAmount}>+</div>
      </div>
    </div>
  );
};

export default AmountSelector;
