import React, { useState } from 'react';


import styles from './MainBlock.module.scss';
import { IPublicEvent, ITeamMember } from "../../../store/DonationPage/types";
import Button from "../../../components/UI/Button/Button";
import DonationsProgress from "../../../components/progress/DonationsProgress/DonationsProgress";
import moment from "moment";
import Beneficiary from "../Beneficiary/Beneficiary";
import DonationsProgressCentered
  from "../../../components/progress/DonationsProgressCentered/DonationsProgressCentered";
import MemberDetails from "./MemberDetails/MemberDetails";
import { IBook } from "../../../types";

interface Props {
  event: IPublicEvent;
  member?: ITeamMember;
  bookLists: Array<IBook>,
  onDonateClick: () => void,
  onJoinClick: () => void
}


const MainBlock = ({event, member, bookLists, onDonateClick, onJoinClick}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  let daysLeft = moment(event.end).diff(moment(), 'days');
  daysLeft = daysLeft > 0 ? daysLeft : 0;

  setTimeout(() => {
    setIsVisible(true)
  }, 100);

  return (
    <div className={`${styles.MainBlock} ${isVisible ? styles.visible : styles.hidden}`}>
      <div className={styles.title}>{event.name}</div>
      <div className={styles.daysLeft}>
        {event.closed ?
          <>The fundraiser is now closed</> :
          <>There are <strong>{daysLeft} days left</strong> in this fundraiser</>
        }
      </div>

      <Beneficiary user={event.invite.from}/>


      <div className={styles.controls}>

        {event.invite.from.role === 'TM' ?
          <DonationsProgress amount={member ? member.progress.raisedSum : 0}
                             currency={event.currency}
                             goal={member ? member?.team.goals.fundraisingPerMember : event.goals.fundraisingPerMember}
                             goalLabel={`${event.invite.from.firstName}'s goal`}/> :

          <DonationsProgressCentered amount={event.progress.raisedSum}
                                     currency={event.currency}
                                     goal={event.goals.fundraising}
                                     donors={event.progress.donationsCount}
          />
        }

        <Button onClick={onDonateClick}
                disabled={event.closed || !event.stripeAccount}
                className={styles.button}>
          {event.invite.from.role === 'TM' ?
            <>Donate to {event.invite.from.firstName}</> :
            <>Donate now</>
          }
        </Button>

        {event.invite.from.role !== 'TM' && event.teams.length > 0 &&
        <Button onClick={onJoinClick}
                type="thin"
                className={styles.button}>
          Join fundraiser
        </Button>
        }

        {event.invite.from.role === 'TM' &&
        <MemberDetails event={event} member={member} bookLists={bookLists}/>
        }

      </div>
    </div>
  );
};

export default MainBlock;
