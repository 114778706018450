import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { store } from './store';
import { agent } from './cordova-agent';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import './index.scss';
import { ACCESS_TOKEN, VIEWER_ROLE } from "./const";


Sentry.init({
  dsn: 'https://d06c010e840a47dc8d1a63d829cbf01b@o308681.ingest.sentry.io/5374645',
  release: process.env.REACT_APP_VERSION,
});

ReactDOM.render(
  <Provider store={store}>
    {agent.cordova ?
      <HashRouter><Main/></HashRouter> :
      <BrowserRouter><Main/></BrowserRouter>
    }
  </Provider>,
  document.getElementById('root')
);

let params = new URLSearchParams(window.location.search);
const accessToken = params.get(ACCESS_TOKEN);

if (accessToken) {
  console.log('log in by access token');
  if (params.get(VIEWER_ROLE)) {
    localStorage.setItem(VIEWER_ROLE, params.get(VIEWER_ROLE) + '');
  }
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  let path = window.location.pathname;
  window.location.href = path === '/log-in' ? '/' : path;
}


agent.initialize();
