import React from 'react';

import logoIcon from '../../../../assets/images/logo/color_logo_icon.svg';
import styles from './index.module.scss';
import { Select } from "../../../../components";
import { ReactComponent as RWLogo } from '../../../../assets/images/logo/RW_logo.svg';
import { formatAmount } from "../../../../components/UI/GetCurrency/GetCurrency";

interface Props {
  accepted: string;
  currency: string;
  schoolName: string;
  fee: number,
  onChange: (accepted: string) => void
}

const DonorFee = ({accepted, fee, schoolName, currency, onChange}: Props) => {


  return (
    <div className={`${styles.wrapper}`}>

      <img src={logoIcon} className={styles.icon}/>
      <div className={styles.title}>
        Would you like to cover the service fees associated with your donation?
        Your support will allow {schoolName} to receive 100% of your pledge gift.
      </div>


      <div className={styles.form}>
        <div>
          <Select value={accepted} onChange={onChange}>
            <option value={'false'}>
              No, but thanks for asking!
            </option>
            <option value={'true'}>
              Yes, I will cover the service fees {accepted == 'true' ? formatAmount(fee, currency):''}
            </option>
          </Select>
        </div>

      </div>
    </div>
  );
};

export default DonorFee;
