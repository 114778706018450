import React, { useState } from 'react';
import styles from './index.module.scss';
import { formatAmount } from "../../../../components/UI/GetCurrency/GetCurrency";
import { arrow } from "../../../../components/ListLeaderboard/icon";

interface Props {
  donationAmount: number;
  bookBoxAmount: number;
  feesAmount: number;
  chargeAmount: number;
  currency: string
}

const Details = ({donationAmount, chargeAmount, bookBoxAmount, feesAmount, currency}: Props) => {

  const [visible, setVisible] = useState(true);


  return (
    <div className={`${styles.wrapper}`}>

      <div
        onClick={()=> setVisible(!visible)}
        className={styles.showHide}>{visible? `Hide details`:`Show details`} &nbsp;<img
        className={visible? styles.visible : ''} alt='arrow' src={arrow}/>
      </div>

      {visible ?
        <table>
          <tbody>
          <tr>
            <td className={styles.label}>Cash Pledge Donation:</td>
            <td className={styles.value}>{formatAmount(donationAmount.toFixed(2), currency)}</td>
          </tr>
          <tr>
            <td className={styles.label}>Book Gift Offer:</td>
            <td className={styles.value}>{formatAmount(bookBoxAmount.toFixed(2), currency)}</td>
          </tr>
          <tr>
            <td className={styles.label}>Service Fees:</td>
            <td className={styles.value}>{formatAmount(feesAmount.toFixed(2), currency)}</td>
          </tr>
          <tr>
            <td className={styles.label}>Total Pledge Donation:</td>
            <td className={styles.valueTotal}>{formatAmount(chargeAmount.toFixed(2), currency)}</td>
          </tr>
          </tbody>
        </table> : ''}

    </div>
  );
};

export default Details;
