import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { agent } from "../cordova-agent";
import { VIEWER_ROLE } from "../const";


const baseURL = agent.cordova && agent.isAndroid() ? "https://app.readformyschool.com" : "";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseURL
});

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<any> => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'X-Viewer-Role': `${localStorage.getItem(VIEWER_ROLE) || 'TM'}`,
    };
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      // localStorage.clear();
      // window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default (type: string, url: string, body?: any) => {
  let contentType = 'application/json';
  if (body) {
    body = encodeToBody(body);
    contentType = 'application/x-www-form-urlencoded';
  }
  return fetch(baseURL + url, {
    method: type,
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'X-User-Role': `${localStorage.getItem(VIEWER_ROLE) || 'TM'}`,
    },
  });
};

const encodeToBody = (params: any) => {
  var formBody = [];
  for (var property in params) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&") + '';
}

export const isTMViewer=()=>{
  return !localStorage.getItem(VIEWER_ROLE) || localStorage.getItem(VIEWER_ROLE) === 'TM';
}
