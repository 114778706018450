import React, { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { IDonations as Donations } from '../../types';
import { GetCurrency, Modal } from '../';

import styles from './Donation.module.scss';

interface IDonation {
  donation: Donations;
  index: number;
  key: number;
}

const Donation: React.FC<IDonation> = ({donation, index}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenBookGift, setIsOpenBookGift] = useState<boolean>(false);

  const date = moment(donation.created);
  const changeColor = () => {
    const id = index / 3;
    if (index < 3) {
      return index;
    }
    if (Math.trunc(id) === id) {
      return 0;
    }
    return Math.round(index / 3);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className={cn(styles.wrapperDonation, styles[`donationBackground${changeColor()}`])}
      >
        <div className={styles.donation}>
          <span className={styles.donorName}>{donation.donorName}</span>
          <span className={styles.donorMessage}> {donation.donorMessage}</span>
        </div>
        <span className={styles.donorAmount}>
          <GetCurrency value={donation.amount}/>
        </span>
      </button>

      {donation.bookBoxAmount > 0 ?
        <button
          onClick={() => setIsOpenBookGift(true)}
          className={cn(styles.wrapperDonation, styles[`donationBackground${changeColor()}`])}
        >
          <div className={styles.donation}>
            <span className={styles.donorName}>{donation.donorName}</span>
            <span className={styles.donorMessage}>Book Gift Donation</span>
          </div>
          <span className={styles.donorAmount}>
          <GetCurrency value={donation.bookBoxAmount}/>
        </span>
        </button> : ''}

      <Modal
        title={donation.donorName}
        isOpen={isOpen}
        textBTN='Say thank you!'
        styleModal={{minHeight: 100}}
        close={() => {
          setIsOpen(false);
        }}
        onClickBTN={() => {
          window.location.href = `mailto:${donation.donorEmail}?subject=Thank you for you support!`;
        }}
      >
        <div className={styles.modalContent}>
          <span className={styles.dateDonation}>{moment(date).format('LL')}</span>
          <span className={styles.donorAmountModal}>
            <GetCurrency value={donation.amount}/>
          </span>
          <span className={styles.donorMessageModal}>{donation.donorMessage}</span>
        </div>
      </Modal>


      <Modal
        title={donation.donorName}
        isOpen={isOpenBookGift}
        textBTN='Say thank you!'
        styleModal={{minHeight: 100}}
        close={() => {
          setIsOpenBookGift(false);
        }}
        onClickBTN={() => {
          window.location.href = `mailto:${donation.donorEmail}?subject=Thank you for you support!`;
        }}
      >
        <div className={styles.modalContent}>
          <span className={styles.dateDonation}>{moment(date).format('LL')}</span>
          <span className={styles.donorAmountModal}>
            <GetCurrency value={donation.bookBoxAmount}/>
          </span>
          <span className={styles.donorMessageModal}>Book Gift Donation</span>
        </div>
      </Modal>
    </>
  );
};

export default Donation;
