import { axiosInstance } from './';
import load from "./index";

export default {
  getEventsData() {
    return axiosInstance.get('/api/v1/events/current');
  },

  getPublicEvent(code: string) {
    return axiosInstance.get(`/api/v1/events?code=${code}`);
  },

  getDonations(eventId: number, limit: number) {
    return axiosInstance.get(`/api/v1/events/${eventId}/donations?limit=${limit}`);
  },

  payment(request: IPaymentRequest) {
    return load('POST', `/api/v1/events/${request.eventId}/payments`, request);
  },

  orderSummary(request: OrderRequest) {
    return axiosInstance.get(`/api/v1/events/${request.eventId}/payments/order`, {
      params: request
    })
  },

  getConfig() {
    return axiosInstance.get('/api/v1/config');
  },
};

interface IPaymentRequest {
  eventId: number,
  stripeToken: string,
  donationAmount: number,
  bookBoxAmount: number,
  donorFee: string,
  tipsAmount: number,
  stripeEmail: string,
  name: string,
  message: string,
  public: boolean,
  matchCorp: boolean,
  giftAid: boolean,
  targetUserId: number
}


export interface OrderRequest {
  eventId: number,
  donationAmount: number,
  bookBoxAmount: number,
  donorFee: boolean,
  tipsAmount: number
}

export interface OrderSummary {
  chargeAmount: number,
  feeAmount: number,
  processingFeeAmount: number
}