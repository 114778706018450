import React from 'react';

import logoIcon from '../../../../assets/images/logo/color_logo_icon.svg';
import styles from './index.module.scss';
import { Select } from "../../../../components";
import bannerSrc from "../../../../assets/images/illustrations/buy_book.png"
import { ReactComponent as RWLogo } from '../../../../assets/images/logo/RW_logo.svg';

interface Props {
  displayName: string,
  schoolName: string;
  amount: string;
  onChange: (amount: string) => void
}

const BookOptIn = ({schoolName, amount, displayName, onChange}: Props) => {


  return (
    <div className={`${styles.wrapper}`}>
      <img className={styles.banner} src={bannerSrc} alt=""></img>
      <div className={styles.header}>
        <div className={styles.title}>
          Help Us Spread the Joy of Reading at <strong>{schoolName}</strong>
        </div>
      </div>

      <div className={styles.text}>
        For an additional <strong>${amount ? amount : 10}</strong>, you can support our <strong>Books for
        All</strong> program. This gift provides a book to <strong>{displayName}</strong> and donates a book on your
        behalf for a child at <strong>{schoolName}</strong>.
        With your help, we can close the literacy gap one book at a time!
      </div>


      <div className={styles.form}>
        <div>
          <Select value={amount} onChange={onChange}>
            <option value={'0'}>
              No, but thanks for asking!
            </option>
            <option value={'10'}>
              Yes, please add $10 to my donation
            </option>
            {/* <option value={'30'}>
              Yes, please add $30 to my donation
            </option>*/}

          </Select>
        </div>

      </div>
    </div>
  );
};

export default BookOptIn;
